var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"flex-nowrap",staticStyle:{"width":"100%","height":"100%"},attrs:{"no-gutters":"","dense":""}},[_c('div',{staticClass:"background",staticStyle:{"flex-grow":"1"}},[_c('v-subheader',{staticStyle:{"position":"relative"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.TABLE_HEADER'))+" "),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-0",style:({
          transition: 'max-width 0.5s ease-in-out',
          maxWidth: _vm.searchTable !== false ? '300px' : 0
        }),attrs:{"value":_vm.searchTable || '',"dense":"","hide-details":"","single-line":"","autofocus":"","label":_vm.$vuetify.lang.t('$vuetify.production.SEARCH'),"placeholder":_vm.$vuetify.lang.t('$vuetify.production.SEARCH'),"type":"search"},on:{"input":function($event){_vm.$router
            .replace({
              query: Object.assign({}, _vm.$route.query, {search: ($event || '').trim()})
            })
            .catch(_vm.noop)},"blur":function($event){_vm.searchTable ||
            _vm.$router
              .replace({ query: Object.assign({}, _vm.$route.query, {search: undefined}) })
              .catch(_vm.noop)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.$router
            .replace({ query: Object.assign({}, _vm.$route.query, {search: undefined}) })
            .catch(_vm.noop)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pa-3"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"right":"0"},attrs:{"icon":"","small":"","absolute":""},on:{"click":function($event){_vm.searchTable !== false ||
                    _vm.$router
                      .replace({
                        query: Object.assign({}, _vm.$route.query, {search: ''})
                      })
                      .catch(_vm.noop)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiMagnify)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.SEARCH'))+" ")])])]},proxy:true}])}),(_vm.userGroups.includes(_vm.ADMIN_USER_GROUP))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialogShow = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiTablePlus))])],1)]}}],null,false,2946965331)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.ADD_DEVICE'))+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.getProductionDeviceList}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiRefresh))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.REFRESH'))+" ")])])],1),_c('div',{style:(_vm.$vuetify.breakpoint.mdAndUp
          ? 'height: calc(100vh - 64px - 48px); position: relative;'
          : 'height: calc(100% - 48px); position: relative')},[_c('v-data-table',{style:(_vm.$vuetify.breakpoint.mdAndUp ? 'position: absolute; inset: 0' : ''),attrs:{"loading":_vm.loading,"headers":_vm.tableHeaders,"items":_vm.tableItems,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":"","search":_vm.searchTable || '',"custom-filter":function (value, search, item) { return JSON.stringify(item).toUpperCase().includes(search.toUpperCase()); },"fixed-header":"","height":"calc(100vh - 64px - 48px - 59px)","items-per-page":50,"footer-props":{
          itemsPerPageOptions: [50]
        }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('v-spacer',{staticStyle:{"height":"58px"}})]},proxy:true},{key:"item.qr",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.qrCodeDevice = item}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiQrcode)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.OPEN_QR_CODE'))+" ")])])]}},{key:"item.connected",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.connected === true
                    ? 'success'
                    : item.connected === false
                    ? 'error'
                    : undefined}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.connected === true ? _vm.mdiCheckNetwork : item.connected === false ? _vm.mdiCloseNetwork : _vm.mdiHelpNetwork)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.connected === true ? ((_vm.$vuetify.lang.t( '$vuetify.production.STATE_CONNECTED_SINCE' )) + " " + (_vm.$moment(item.lastConnectAt).fromNow())) : item.connected === false ? ((_vm.$vuetify.lang.t( '$vuetify.production.STATE_DISCONNECTED_SINCE' )) + " " + (_vm.$moment(item.lastDisconnectAt).fromNow())) : _vm.$vuetify.lang.t('$vuetify.production.STATE_UNKNOWN'))+" ")])])]}},(_vm.userGroups.includes(_vm.ADMIN_USER_GROUP))?{key:"item.remove",fn:function(ref){
                    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.removeUid = item.uid}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiClose)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.REMOVE_DEVICE'))+" ")])])]}}:null,{key:"item.provisioned",fn:function(ref){
                    var item = ref.item;
return [(item.provisioned)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":("/shadows/" + (item.uid) + "?search=" + (item.uid))}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiGhost)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.OPEN_SHADOW'))+" ")])]):[_c('v-btn',{attrs:{"icon":"","disabled":""}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.mdiGhostOff)+" ")])],1)]]}},{key:"item.sub",fn:function(ref){
                    var item = ref.item;
return [(item.sub)?_c('v-row',{staticClass:"flex-nowrap",staticStyle:{"min-height":"100%"},attrs:{"dense":"","no-gutters":"","align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({staticClass:"flex-nowrap",staticStyle:{"min-height":"100%","cursor":"pointer"},attrs:{"dense":"","no-gutters":"","align":"center"},on:{"click":function($event){item.sub &&
                      _vm.$router
                        .push(("/users/" + (item.sub) + "?search=" + (item.sub)))
                        .catch(_vm.noop)}}},'v-row',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiAccountSearch)+" ")]),_vm._v(" "+_vm._s(item.sub)+" "),_c('v-spacer')],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.OPEN_USER'))+" ")])]),(_vm.userGroups.includes(_vm.ADMIN_USER_GROUP))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.removeAccount = item}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiAccountOff))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.REMOVE_LINK'))+" ")])]):_vm._e()],1):(
              item.provisioned && _vm.userGroups.includes(_vm.ADMIN_USER_GROUP)
            )?_c('v-row',{staticClass:"flex-nowrap",staticStyle:{"min-height":"100%"},attrs:{"dense":"","no-gutters":"","align":"center"}},[_vm._v(" - "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.setVerification(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiFlagPlus))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.SET_VERIFICATION'))+" ")])])],1):[_vm._v("-")]]}},{key:"item.type",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(("$vuetify.production." + (item.type.toUpperCase()))))+" ")]}},{key:"item.verification",fn:function(ref){
            var item = ref.item;
return [(_vm.userGroups.includes(_vm.ADMIN_USER_GROUP))?_c('v-edit-dialog',{attrs:{"large":"","cancel-text":_vm.$vuetify.lang.t('$vuetify.production.CANCEL'),"save-text":_vm.$vuetify.lang.t('$vuetify.production.SAVE')},on:{"save":function($event){return _vm.updateVerification(item.uid, item.tmp)},"open":function($event){item.tmp = item.verification}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.production.VERIFICATION_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.production.VERIFICATION_REQUIRED'),"single-line":"","rules":[_vm.requiredRule, _vm.verificationRule]},on:{"keydown":_vm.validateVerificationKeydown,"paste":function($event){_vm.validateVerificationPaste(
                    $event,
                    $event.clipboardData
                      ? $event.clipboardData.getData('text/plain') || ''
                      : ''
                  )},"drop":function($event){_vm.validateVerificationPaste(
                    $event,
                    $event.dataTransfer
                      ? $event.dataTransfer.getData('text/plain') || ''
                      : ''
                  )}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                  var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,true),model:{value:(item.tmp),callback:function ($$v) {_vm.$set(item, "tmp", $$v)},expression:"item.tmp"}})]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({staticClass:"flex-nowrap",staticStyle:{"min-height":"100%"},attrs:{"dense":"","no-gutters":"","align":"center"}},'v-row',attrs,false),on),[_vm._v(" "+_vm._s(item.verification)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiPencil)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.EDIT'))+" ")])])],1):[_vm._v(" "+_vm._s(item.verification)+" ")]]}},{key:"item.created",fn:function(ref){
                  var item = ref.item;
return [(item.created)?[_vm._v(" "+_vm._s(_vm._f("moment")(item.created))+" ")]:[_vm._v("-")]]}},{key:"item.changedAt",fn:function(ref){
                  var item = ref.item;
return [(item.changedAt)?[_c('v-row',{staticStyle:{"min-height":"100%"},attrs:{"dense":"","no-gutters":"","align":"center","title":item.changedBy}},[_vm._v(" "+_vm._s(_vm._f("moment")(item.changedAt))+" ")])]:[_vm._v("-")]]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500"},on:{"click:outside":_vm.resetDialog},model:{value:(_vm.dialogShow),callback:function ($$v) {_vm.dialogShow=$$v},expression:"dialogShow"}},[_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.dialogValid),callback:function ($$v) {_vm.dialogValid=$$v},expression:"dialogValid"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.ADD_DEVICE'))+" ")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.production.UID_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.production.UID_REQUIRED'),"prefix":_vm.dialogDeviceType === _vm.DeviceType_Singular.GATEWAY
                  ? 'G-'
                  : _vm.dialogDeviceType === _vm.DeviceType_Singular.INTERFACE
                  ? 'I-'
                  : 'T-',"rules":[_vm.requiredRule, _vm.uidRule],"required":"","autofocus":""},on:{"keydown":_vm.validateUidKeydown,"paste":function($event){$event.stopPropagation();$event.preventDefault();_vm.validateUidPaste(
                  $event.clipboardData
                    ? $event.clipboardData.getData('text/plain') || ''
                    : ''
                )},"drop":function($event){$event.stopPropagation();$event.preventDefault();_vm.validateUidPaste(
                  $event.dataTransfer
                    ? $event.dataTransfer.getData('text/plain') || ''
                    : ''
                )}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.dialogUid),callback:function ($$v) {_vm.dialogUid=$$v},expression:"dialogUid"}}),_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.production.DEVICE_TYPE_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.production.DEVICE_TYPE_REQUIRED'),"rules":[_vm.requiredRule],"items":_vm.deviceTypes,"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.dialogDeviceType),callback:function ($$v) {_vm.dialogDeviceType=$$v},expression:"dialogDeviceType"}}),_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.production.VERIFICATION'),"placeholder":_vm.$vuetify.lang.t('$vuetify.production.VERIFICATION'),"rules":[_vm.verificationRule]},on:{"keydown":_vm.validateVerificationKeydown,"paste":function($event){_vm.validateVerificationPaste(
                  $event,
                  $event.clipboardData
                    ? $event.clipboardData.getData('text/plain') || ''
                    : ''
                )},"drop":function($event){_vm.validateVerificationPaste(
                  $event,
                  $event.dataTransfer
                    ? $event.dataTransfer.getData('text/plain') || ''
                    : ''
                )}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.dialogVerification),callback:function ($$v) {_vm.dialogVerification=$$v},expression:"dialogVerification"}})],1),_c('v-card-actions',[_c('small',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.REQUIRED_FIELD'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.resetDialog}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.loading || !_vm.dialogValid},on:{"click":_vm.addProductionDevice}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.ADD'))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"value":!!_vm.removeUid,"max-width":"400"},on:{"input":function($event){_vm.removeUid = ''}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.REMOVE_DEVICE'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.removeUid)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.CONFIRM_REMOVE'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.removeUid = ''}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.removeProductionDevice}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.REMOVE'))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"value":!!_vm.qrCodeDevice,"max-width":"300"},on:{"input":function($event){_vm.qrCodeDevice = null}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.QR_CODE'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.qrCodeDevice ? _vm.qrCodeDevice.uid : '')+" ")]),_c('v-card-text',[(_vm.qrCodeDevice)?_c('VueQrcode',{attrs:{"value":JSON.stringify({
                uid: _vm.qrCodeDevice.uid,
                verification: _vm.qrCodeDevice.verification
              }),"color":{
              dark: _vm.$vuetify.theme.dark ? '#ffffffff' : '#000000ff',
              light: '#ffffff00'
            },"width":252,"margin":0}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.qrCodeDevice = null}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.CLOSE'))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"value":!!_vm.removeAccount,"max-width":"400"},on:{"input":function($event){_vm.removeAccount = null}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.REMOVE_LINK_SHORT'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.removeAccount ? _vm.removeAccount.sub : '')+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.CONFIRM_REMOVE_LINK'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.removeAccount = null}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.removeDeviceLink}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.REMOVE_SOFT'))+" ")])],1)],1)],1)],1),(_vm.showConsole && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-divider',{staticClass:"ma-0",staticStyle:{"right":"0","position":"sticky","z-index":"2","flex-shrink":"0"},attrs:{"vertical":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }