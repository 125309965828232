




























































































































































































































































































































































































































































































































































































































































































import {
  addProductionDevice,
  getProductionDeviceList,
  updateProductionDevice,
  removeProductionDevice,
  addProductionDeviceOfficial,
  removeDeviceLink,
  updateShadow
} from '../../api/admin';
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  mdiTablePlus,
  mdiRefresh,
  mdiPencil,
  mdiClose,
  mdiMagnify,
  mdiQrcode,
  mdiAccountSearch,
  mdiGhostOff,
  mdiGhost,
  mdiAccountOff,
  mdiFlagPlus,
  mdiCheckNetwork,
  mdiCloseNetwork,
  mdiHelpNetwork
} from '@mdi/js';
import type { DeviceShadow, ValidationRule } from '../../typings';
import { DeviceType_Singular, ProducedDevice } from '../../typings';
import VueQrcode from 'vue-qrcode';
import { getModule } from 'vuex-module-decorators';
import { SettingsModule } from '../../plugins/store';
import { noop } from 'vue-class-component/lib/util';
import { Hub } from '@aws-amplify/core';
import _ from 'lodash';
import Auth from '@aws-amplify/auth';
import { ADMIN_USER_GROUP } from '../../constants';

const settingsStore: SettingsModule = getModule(SettingsModule);

@Component({
  components: {
    VueQrcode
  }
})
export default class ProductionTableView extends Vue {
  private readonly ADMIN_USER_GROUP: typeof ADMIN_USER_GROUP =
    ADMIN_USER_GROUP;
  private readonly DeviceType_Singular: typeof DeviceType_Singular =
    DeviceType_Singular;
  private readonly mdiRefresh: string = mdiRefresh;
  private readonly mdiTablePlus: string = mdiTablePlus;
  private readonly mdiPencil: string = mdiPencil;
  private readonly mdiClose: string = mdiClose;
  private readonly mdiMagnify: string = mdiMagnify;
  private readonly mdiQrcode: string = mdiQrcode;
  private readonly mdiAccountSearch: string = mdiAccountSearch;
  private readonly mdiAccountOff: string = mdiAccountOff;
  private readonly mdiGhost: string = mdiGhost;
  private readonly mdiGhostOff: string = mdiGhostOff;
  private readonly mdiFlagPlus: string = mdiFlagPlus;
  private readonly mdiHelpNetwork: string = mdiHelpNetwork;
  private readonly mdiCloseNetwork: string = mdiCloseNetwork;
  private readonly mdiCheckNetwork: string = mdiCheckNetwork;
  private readonly noop: typeof noop = noop;
  private readonly requiredRule: ValidationRule = (
    v: unknown
  ): true | string => !!v || '$vuetify.production.REQUIRED';
  private readonly uidRule: ValidationRule = (v: string): true | string =>
    /^(?:0[1-9]|[12][0-9]|3[01])(?:0[1-9]|1[012])(?:19|20)\d\d-\d{8}$/.test(
      v
    ) || '$vuetify.production.INVALID';
  private readonly verificationRule: ValidationRule = (
    v: string
  ): true | string =>
    !v || /^\d{8}$/.test(v) || '$vuetify.production.INVALID';

  private qrCodeDevice: ProducedDevice | null = null;
  private dialogShow: boolean = false;
  private dialogValid: boolean = false;
  private dialogUid: string = '';
  private dialogVerification: string = '';
  private dialogDeviceType: DeviceType_Singular = DeviceType_Singular.GATEWAY;
  private removeAccount: ProducedDevice | null = null;
  private loading: boolean = true;
  private sortBy: string = 'created';
  private sortDesc: boolean = true;
  private removeUid: string = '';
  private tableItems: ProducedDevice[] = [];
  private userGroups: string[] = [];

  private get tableHeaders(): Array<{
    text: string;
    value: string;
    sortable: boolean;
    width?: string | number;
    align?: 'start' | 'center' | 'end';
    sort?: (a: never, b: never) => number;
  }> {
    return [
      {
        text: this.$vuetify.lang.t('$vuetify.production.QR_CODE'),
        value: 'qr',
        sortable: false,
        width: '84px',
        align: 'center'
      },
      {
        text: this.$vuetify.lang.t('$vuetify.production.CONNECTION_STATE'),
        value: 'connected',
        sortable: true,
        width: '117px',
        align: 'center',
        sort: (a1: boolean | undefined, b1: boolean | undefined): number => {
          const a2: number = typeof a1 === 'boolean' ? +a1 : -1;
          const b2: number = typeof b1 === 'boolean' ? +b1 : -1;
          return b2 - a2;
        }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.production.PROVISIONED'),
        value: 'provisioned',
        sortable: true,
        width: '124px',
        align: 'center'
      },
      {
        text: this.$vuetify.lang.t('$vuetify.production.UID'),
        value: 'uid',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.production.DEVICE_TYPE'),
        value: 'type',
        sortable: true,
        width: '120px'
      },
      {
        text: this.$vuetify.lang.t('$vuetify.production.VERIFICATION'),
        value: 'verification',
        sortable: false
      },
      {
        text: this.$vuetify.lang.t('$vuetify.production.USER'),
        value: 'sub',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.production.CREATED'),
        value: 'created',
        sortable: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.production.CHANGED'),
        value: 'changedAt',
        sortable: true
      },
      {
        text: '',
        value: 'remove',
        sortable: false,
        width: '0',
        align: 'end'
      }
    ];
  }

  private get deviceTypes(): Array<{ text: string; value: string }> {
    return Object.values(DeviceType_Singular).map(
      (value: string): { text: string; value: string } => ({
        text: this.$vuetify.lang.t(
          `$vuetify.production.${value.toUpperCase()}`
        ),
        value
      })
    );
  }

  private get showConsole(): boolean {
    return settingsStore.console;
  }

  @Prop({ type: [String, Boolean], default: false })
  private readonly searchTable!: string | false;

  private async created(): Promise<void> {
    this.userGroups =
      (
        await Auth.currentSession().catch((): undefined => undefined)
      )?.getIdToken()?.payload?.['cognito:groups'] || [];
  }

  private mounted(): void {
    this.getProductionDeviceList();
  }

  private validateUidKeydown(event: KeyboardEvent): void {
    const isForbiddenChar: boolean =
      event.key.length === 1 &&
      (this.dialogUid.length >= 17 ||
        (this.dialogUid.length === 8 && event.key !== '-') ||
        (this.dialogUid.length !== 8 && !/\d/.test(event.key)));
    const isAllowedModifier: boolean = event.ctrlKey || event.metaKey;
    if (isForbiddenChar && !isAllowedModifier) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private validateUidPaste(uid: string): void {
    uid = uid.trim();
    if (
      /^(?:[GIT]-)?(?:0[1-9]|[12][0-9]|3[01])(?:0[1-9]|1[012])(?:19|20)\d\d-\d{8}$/i.test(
        uid
      )
    ) {
      if (uid.length > 17) {
        if (uid.startsWith('G-')) {
          this.dialogDeviceType = DeviceType_Singular.GATEWAY;
        } else if (uid.startsWith('I-')) {
          this.dialogDeviceType = DeviceType_Singular.INTERFACE;
        } else if (uid.startsWith('T-')) {
          this.dialogDeviceType = DeviceType_Singular.OTHER;
        }
        uid = uid.substring(2);
      }
      this.dialogUid = uid;
    }
  }

  private validateVerificationKeydown(event: KeyboardEvent): void {
    const isForbiddenChar: boolean =
      event.key.length === 1 &&
      (this['dialogVerification'].length >= 8 || !/\d/.test(event.key));
    const isAllowedModifier: boolean = event.ctrlKey || event.metaKey;
    if (isForbiddenChar && !isAllowedModifier) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private validateVerificationPaste(
    event: Event,
    verification: string
  ): void {
    verification = verification.trim();
    if (!/^\d+$/.test(verification)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private getProductionDeviceList(): void {
    this.loading = true;
    getProductionDeviceList()
      .then((list: ProducedDevice[]): void => void (this.tableItems = list))
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private addProductionDevice(): void {
    this.loading = true;
    let uid: string = '';
    if (this.dialogDeviceType === DeviceType_Singular.GATEWAY) {
      uid = `G-${this.dialogUid}`;
    } else if (this.dialogDeviceType === DeviceType_Singular.INTERFACE) {
      uid = `I-${this.dialogUid}`;
    } else if (this.dialogDeviceType === DeviceType_Singular.OTHER) {
      uid = `T-${this.dialogUid}`;
    }
    let prom: Promise<ProducedDevice>;
    if (this.dialogVerification) {
      prom = addProductionDevice(
        uid,
        this.dialogDeviceType,
        this.dialogVerification
      );
    } else {
      prom = addProductionDeviceOfficial(uid);
    }
    prom
      .then((device: ProducedDevice): void => {
        this.tableItems.push(device);
        this.resetDialog();
        this.sortBy = 'created';
        this.sortDesc = true;
      })
      .catch((error: Error): void => {
        this.dialogShow = false;
        this.showError(error.message);
      })
      .finally((): void => void (this.loading = false));
  }

  private removeProductionDevice(): void {
    const index: number = this.tableItems.findIndex(
      (item: ProducedDevice): boolean =>
        item.uid.toUpperCase() === this.removeUid.toUpperCase()
    );
    if (index < 0) {
      return;
    }
    const uid: string = this.removeUid;
    this.loading = true;
    this.removeUid = '';
    removeProductionDevice(uid)
      .then((): void => void this.tableItems.splice(index, 1))
      .catch((error: Error): void => void this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private updateVerification(uid: string, verification: string): void {
    const index: number = this.tableItems.findIndex(
      (item: ProducedDevice): boolean =>
        item.uid.toUpperCase() === uid.toUpperCase()
    );
    if (index < 0) {
      return;
    }
    if (!/^\d{8}$/.test(verification)) {
      this.showError(this.$vuetify.lang.t('$vuetify.production.INVALID'));
      return;
    }
    this.loading = true;
    updateProductionDevice({
      ...this.tableItems[index],
      verification
    })
      .then((device: ProducedDevice): void => {
        this.tableItems[index] = device;
        this.sortBy = 'changedAt';
        this.sortDesc = true;
      })
      .catch((error: Error): void => void this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private removeDeviceLink(): void {
    const index: number = this.tableItems.findIndex(
      (item: ProducedDevice): boolean =>
        item.uid.toUpperCase() === this.removeAccount?.uid.toUpperCase()
    );
    this.removeAccount = null;
    if (index < 0) {
      return;
    }
    const uid: string = this.tableItems[index].uid;
    const sub: string | undefined = this.tableItems[index].sub;
    if (!uid || !sub) {
      return;
    }
    this.loading = true;
    removeDeviceLink(sub, uid)
      .then((): void => this.$delete(this.tableItems[index], 'sub'))
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private async setVerification(item: ProducedDevice): Promise<void> {
    this.loading = true;
    try {
      await updateShadow(
        item.uid,
        _.set<DeviceShadow>({}, 'state.reported.verification', true)
      );
      Hub.dispatch('appAlert', {
        event: 'success',
        message: this.$vuetify.lang.t(
          '$vuetify.production.SET_VERIFICATION_COMPLETE'
        )
      });
    } catch (e) {
      this.showError(e.message);
    } finally {
      this.loading = false;
    }
  }

  private resetDialog(): void {
    this.dialogShow = false;
    this.dialogUid = '';
    this.dialogVerification = '';
    this.dialogDeviceType = DeviceType_Singular.GATEWAY;
  }
}
